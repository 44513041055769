import classNames from 'classnames'

type LabelProps = {
   children: React.ReactNode
   className?: string
   outline?: boolean
   bsStyle?: 'default' | 'success' | 'warning' | 'danger'
   light?: boolean
   inactive?: boolean
}

export const Label = ({ children, className, bsStyle = 'default', light, outline, inactive }: LabelProps) => {
   const result = classNames(
      'inline leading-none text-center align-baseline rounded whitespace-nowrap px-2 py-1',
      className,
      {
         'cursor-pointer': !inactive,
         'font-bold text-[75%]': !light,
         'text-[90%]': light,
         ...(!outline
            ? {
                 'bg-gray-600 text-white': bsStyle === 'default',
                 'bg-green-500 text-white': bsStyle === 'success',
                 'bg-yellow-500 text-white': bsStyle === 'warning',
                 'bg-red-500 text-white': bsStyle === 'danger',
              }
            : {
                 'border bg-transparent': true,
                 'border-gray-400': bsStyle === 'default',
                 'border-green-500 text-green-500': bsStyle === 'success',
                 'border-yellow-500 text-yellow-500': bsStyle === 'warning',
                 'border-red-500 text-red-500': bsStyle === 'danger',
              }),
      },
   )
   return <span className={result}>{children}</span>
}
